import actionTypes from "./actions";

const initialState = {
  isLoading: false,
  navigation: [],
  authData: {
    email: localStorage.getItem("email"),
    name: localStorage.getItem("name"),
  },
  self: {},
};

const reducer = (state = initialState, action) => {
  let navigation;
  let authData;
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actionTypes.SET_NAVIGATION:
      navigation = { ...state.navigation };
      if (action.navigation && action.navigation) {
        navigation = action.navigation;
      }
      return {
        ...state,
        navigation,
      };
    case actionTypes.UPDATE_PROFILE_PIC:
      authData = { ...state.authData };
      if (action.profilePic) {
        authData.profilePic = action.profilePic;
      }
      return {
        ...state,
        authData,
      };
    case actionTypes.SET_SELF:
      return {
        ...state,
        self: action.data,
      };
    default:
      return state;
  }
};

export default reducer;
