export const getBaseApi = function () {
  if (window.location.hostname === "localhost") {
    return window.location.origin.split("300")[0] + "5000";
  }
  return "https://api.matchmysanta.com";
};

export const getMainUrl = function () {
  if (window.location.hostname === "localhost") {
    return "http://localhost:3001";
  }
  return "https://matchmysanta.com";
};

export const showExceptions = function () {
  return window.location.hostname !== "matchmysanta.com";
};

export const isProd = function () {
  return window.location.hostname === "matchmysanta.com";
};

export const BackendAPIs = {
  auth: {
    login: "/auth/login",
    menuItems: "/auth/menuItems",
    self: "/auth/self",
    changePassword: "/auth/changePassword",
    signup: "/auth/signup",
    validateEmail: "/auth/validate-email",
    resetPwd: "/auth/reset-pwd",
    resetPwdAction: "/auth/reset-pwd-action",
  },
  admin: {
    add: "/admin/addAdmin",
    email: "/admin/getAdminEmail",
    delete: "/admin/delete",
    globalPropsTypes: "/admin/globalPropsTypes",
    globalPropsKeys: "/admin/globalPropsKeys",
    updateGlobalProps: "/admin/updateGlobalProps",
    scheduleTaskList: "/admin/schedule-task-list",
    scheduleTaskOffer: "/admin/schedule-task-offer",
    triggerBatchProcess: "/admin/triggerBatchProcess",
    grantTask: "/admin/grant-schedule-task",
    revokeTask: "/admin/revoke-schedule-task",
    getUsersList: "/admin/getUsersList",
    getSideNav: "/admin/getSideNav",
    updateSideNav: "/admin/updateSideNav",
    removeSideNav: "/admin/removeSideNav",
    setDefaultSideNav: "/admin/setDefaultSideNav",
    loginAsUser: "/admin/loginAsUser",
  },
  managerAccount: {
    addNewManager: "/managers/addNewManager",
    getManagers: "/managers/getManagers",
    updateManagerSideNav: "/managers/updateManagerSideNav",
    getManagerSideNav: "/managers/getManagerSideNav",
  },
  generic: {
    downloadXls: "/generic/downloadXls",
    getProfilePicture: "/generic/getProfilePicture",
    setProfilePicture: "/generic/setProfilePicture",
  },
};
