import { connect } from "react-redux";
import actionTypes from "../../store/actions";
import { ReactComponent as TopLeftImage } from "../../assets/svgs/topLeftImage.svg";
import { ReactComponent as TopRightImage } from "../../assets/svgs/topRightImage.svg";
import { ReactComponent as BottomLeftImage } from "../../assets/svgs/bottomLeftImage.svg";
import { ReactComponent as BottomRightImage } from "../../assets/svgs/bottomRightImage.svg";
import { useEffect, useState } from "react";
import { postCall } from "../../http-services";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import { useParams } from "react-router";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";

interface ValidateEmailProps {
  setLoading: any;
}

interface Params {
  id: string;
}

const ValidateEmail: React.FC<ValidateEmailProps> = (props: any) => {
  let { id } = useParams<Params>();
  let [response, setResponse] = useState(null);

  useEffect(() => {
    postCall(props.setLoading)(
      getBaseApi() + BackendAPIs.auth.validateEmail,
      {
        id: id,
      },
      false,
      null,
      false,
      true
    ).then((resp) => {
      setResponse(resp.data || "failed");
    });
  }, [props.setLoading, id]);
  return (
    <div className="vaidate-email">
      <div className="top-left">
        <TopLeftImage />
      </div>
      <div className="top-right">
        <TopRightImage />
      </div>
      <div className="bottom-left">
        <BottomLeftImage />
      </div>
      <div className="bottom-right">
        <BottomRightImage />
      </div>
      <div className="page-center">
        <div className="card" id="validation-message">
          <div className="card-body text-center">
            {!response ? (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                className="primary-color"
              ></Spinner>
            ) : response === "success" ? (
              <span
                role="img"
                aria-label="Success"
                className="color-green fa-solid fa-check fa-5x"
              ></span>
            ) : (
              <span
                role="img"
                aria-label="Failed"
                className="color-red fa-solid fa-xmark fa-7x"
              ></span>
            )}
            {!response ? null : response === "success" ? (
              <p className="mb-0">
                Your email is successfully verified.{" "}
                <Link to="/signin">Login here</Link>
              </p>
            ) : (
              <p className="mb-0">
                The verification link has expired or already used.{" "}
                <Link to="/signup">Please signup again</Link>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoading: (isLoading: any) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateEmail);
