import { useState } from "react";
import { connect } from "react-redux";
import { postCall } from "../../http-services";
import actionTypes from "../../store/actions";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import { ReactComponent as TopLeftImage } from "../../assets/svgs/topLeftImage.svg";
import { ReactComponent as TopRightImage } from "../../assets/svgs/topRightImage.svg";
import { ReactComponent as BottomLeftImage } from "../../assets/svgs/bottomLeftImage.svg";
import { ReactComponent as BottomRightImage } from "../../assets/svgs/bottomRightImage.svg";
import { useHistory } from "react-router";

interface LoginProps {
  setLoading: any;
}

const Login: React.FC<LoginProps> = ({ setLoading }) => {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");

  let isInvaidPassword = (pwd: string) => {
    return (
      pwd.length < 8 ||
      pwd.length > 15 ||
      !pwd.match(/[a-z]/g) ||
      !pwd.match(/[0-9]/g)
    );
  };

  let isInvaidEmail = (email: string) => {
    return (
      !email || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    );
  };

  let history = useHistory();
  return (
    <div className="login">
      <div className="top-left">
        <TopLeftImage />
      </div>
      <div className="top-right">
        <TopRightImage />
      </div>
      <div className="bottom-left">
        <BottomLeftImage />
      </div>
      <div className="bottom-right">
        <BottomRightImage />
      </div>
      <div className="page-center">
        <h1>Log In</h1>
        <div className="user-name">
          <div className="lbl">Email</div>
          <div className="input-icon">
            <i className="user-icon">
              <span className="fa-solid fa-envelope fa-2x"></span>
            </i>

            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvaidEmail(email) ? " error-class" : " noerror-class")
              }
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Email Address"
            />
          </div>
        </div>
        <div className="pswd">
          <div className="lbl">Password</div>
          <div className="input-icon">
            <i className="user-icon">
              <span className="fa-solid fa-key fa-2x"></span>
            </i>

            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvaidPassword(password) ? " error-class" : " noerror-class")
              }
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
            />
          </div>
        </div>
        <div
          className="frgt-pswd cursor-pointer"
          onClick={() => {
            history.push("/reset-pwd");
          }}
        >
          Forget password?
        </div>
        <div className="login-btn">
          <button
            className="login-btn"
            onClick={() => {
              postCall(setLoading)(getBaseApi() + BackendAPIs.auth.login, {
                email,
                password,
              }).then((resp) => {
                if (resp.data) {
                  localStorage.setItem("token", resp.data.jwt);
                  localStorage.setItem("name", resp.data.name);
                  localStorage.setItem("email", resp.data.email);
                  window.location.href = "/";
                }
              });
            }}
            disabled={isInvaidEmail(email) || isInvaidPassword(password)}
          >
            Log In
          </button>
        </div>
        <div className="sign-up-link">
          <p className="in-case">Don’t have an account?</p>
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/signup");
            }}
          >
            Sign Up Now
          </p>
        </div>
        <div className="sign-up-link">
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/home");
            }}
          >
            &larr; Go back to home
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoading: (isLoading: any) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
