import logo from "../assets/images/mms_logo.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/" className="brand-logo">
      <img src={logo} alt="logo" style={{ height: "50px" }} />
      <div className="logo-text">
        Match<span className="highlighted-text">MySanta</span>
      </div>
    </Link>
  );
};

export default Logo;
