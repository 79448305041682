import actionTypes from "../../store/actions";
import { connect } from "react-redux";
// import { useHistory } from "react-router-dom";
import HeaderSection from "../../components/HeaderSection";
import GetYourGift from "../../components/GetYourGift";
import JoinTheParty from "../../components/JoinTheParty";
import GetItems from "../../components/GetItems";
import LiveEvent from "../../components/LiveEvent";
import ContactToJoin from "../../components/ContactToJoin";
import FooterBar from "../../components/FooterBar";
interface HomeProps {
  isLoading: boolean;
  setLoading: any;
}

const Home: React.FC<HomeProps> = ({ isLoading, setLoading }) => {
  return (
    <div className="home">
      <HeaderSection />
      <GetYourGift />
      <JoinTheParty />
      <GetItems />
      <LiveEvent />
      <ContactToJoin />
      <FooterBar />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoading: (isLoading: any) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
