import { useState } from "react";
import { connect } from "react-redux";
import { postCall } from "../../http-services";
import actionTypes from "../../store/actions";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import { ReactComponent as TopLeftImage } from "../../assets/svgs/topLeftImage.svg";
import { ReactComponent as TopRightImage } from "../../assets/svgs/topRightImage.svg";
import { ReactComponent as BottomLeftImage } from "../../assets/svgs/bottomLeftImage.svg";
import { ReactComponent as BottomRightImage } from "../../assets/svgs/bottomRightImage.svg";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

interface SignUpProps {
  setLoading: any;
}

const SignUp: React.FC<SignUpProps> = ({ setLoading }) => {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [name, setName] = useState("");
  let [confirmPassword, setCPassword] = useState("");
  let history = useHistory();

  let isInvaidPassword = (pwd: string) => {
    return (
      pwd.length < 8 ||
      pwd.length > 15 ||
      !pwd.match(/[a-z]/g) ||
      !pwd.match(/[0-9]/g)
    );
  };

  let isInvaidEmail = (email: string) => {
    return (
      !email || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    );
  };

  let isInvaidName = (name: string) => {
    return !name || name.length < 3;
  };

  let isInvaidCPwd = (pwd: string, cPwd: string) => {
    return (
      pwd.length < 8 ||
      pwd.length > 15 ||
      !pwd.match(/[a-z]/g) ||
      !pwd.match(/[0-9]/g) ||
      pwd !== cPwd
    );
  };

  return (
    <div className="sign-up">
      <div className="top-left">
        <TopLeftImage />
      </div>
      <div className="top-right">
        <TopRightImage />
      </div>
      <div className="bottom-left">
        <BottomLeftImage />
      </div>
      <div className="bottom-right">
        <BottomRightImage />
      </div>
      <div className="page-center">
        <h1>Sign Up</h1>
        <div className="name">
          <div className="lbl">Full Name</div>
          <div className="input-icon">
            <i className="user-icon">
              <span className="fa-solid fa-user fa-2x"></span>
            </i>
            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvaidName(name) ? " error-class" : " noerror-class")
              }
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Full Name"
            />
          </div>
        </div>
        <div className="user-name">
          <div className="lbl">Email</div>
          <div className="input-icon">
            <i className="user-icon">
              <span className="fa-solid fa-envelope fa-2x"></span>
            </i>
            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvaidEmail(email) ? " error-class" : " noerror-class")
              }
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Email Address"
            />
          </div>
        </div>
        <div className="pswd">
          <div className="lbl">Password</div>
          <div className="input-icon">
            <i className="user-icon">
              <span className="fa-solid fa-key fa-2x"></span>
            </i>

            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvaidPassword(password) ? " error-class" : " noerror-class")
              }
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
            />
          </div>
        </div>
        <div className="cnfm-pswd">
          <div className="lbl">Confirm Password</div>
          <div className="input-icon">
            <i className="user-icon">
              <span className="fa-solid fa-key fa-2x"></span>
            </i>

            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvaidCPwd(password, confirmPassword)
                  ? " error-class"
                  : " noerror-class")
              }
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setCPassword(e.target.value);
              }}
              placeholder="Confirm Password"
            />
          </div>
        </div>
        <div className="signUp-btn">
          <button
            className="signUp-btn"
            onClick={() => {
              postCall(setLoading)(getBaseApi() + BackendAPIs.auth.signup, {
                email,
                password,
                name,
                confirmPassword,
              }).then((resp) => {
                if (resp.data) {
                  toast("Signup successful! Please check your mail.");
                  setName("");
                  setEmail("");
                  setPassword("");
                  setCPassword("");
                }
              });
            }}
            disabled={
              isInvaidEmail(email) ||
              isInvaidPassword(password) ||
              isInvaidName(name) ||
              isInvaidCPwd(password, confirmPassword)
            }
          >
            Sign Up
          </button>
        </div>
        <div className="login-link">
          <p className="in-case">Already have an account?</p>
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/login");
            }}
          >
            Log In Here
          </p>
        </div>
        <div className="login-link">
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/home");
            }}
          >
            &larr; Go back to home
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoading: (isLoading: any) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
