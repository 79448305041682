import sound from "../assets/images/Bell.png";
import design from "../assets/images/Wreath.png";
import TreeImage from "../assets/images/TreeImage1.png";

interface ContainerProps {}

const LiveEvent: React.FC<ContainerProps> = () => {
  return (
    <div className="live-event">
      <div className="left-img">
        <img src={TreeImage} alt="Tree" />
      </div>
      <div className="right-content">
        <div className="about-section">
          <h1 className="about-heading">About the live event</h1>
          <p className="about-desc">
            Fill your Christmas holiday with good cheer, joyful decorations, and
            unforgettable experience. Feel free to attend our Christmas event
            with your children and family and celebrate the most anticipated
            winter holiday. From Christmas carols to activities for adults and
            children, Sannta has a lot to offer.
          </p>
        </div>
        <div className="better-features">
          <div className="feature">
            <div className="logo">
              <img src={design} alt="better design" />
            </div>
            <h1>Beautiful Design</h1>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
            </p>
          </div>
          <div className="feature">
            <div className="logo">
              <img src={sound} alt="great sound" />
            </div>
            <h1>Great Sound</h1>
            <p>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveEvent;
