import hatImg from "../assets/images/Hat.png";
import treeImg from "../assets/images/XmasTree.png";
import bellImg from "../assets/images/JingleBell.png";

interface ContainerProps {}

const GetItems: React.FC<ContainerProps> = () => {
  return (
    <div className="get-items-wrapper">
      <div className="get-items">
        <div className="section-header">
          <h1>Gift Items</h1>
          <p>
            Christmas is an annual festival commemorating the birth of Jesus
            Christ, a cultural celebration among billions of people around the
            world.
          </p>
        </div>
        <div className="item-list">
          <div className="item">
            <div className="logo">
              <img src={hatImg} alt="hat" />
            </div>
            <h1 className="item-name">Hat</h1>
            <p className="item-desc">
              Inspirational Christmas Greetings Messages for your loved ones –
              whether you’re sending a small gift to grandma or need something
              special to write for.
            </p>
          </div>
          <div className="item">
            <div className="logo">
              <img src={treeImg} alt="tree" />
            </div>
            <h1 className="item-name">Tree</h1>
            <p className="item-desc">
              Inspirational Christmas Greetings Messages for your loved ones –
              whether you’re sending a small gift to grandma or need something
              special to write for.
            </p>
          </div>
          <div className="item">
            <div className="logo">
              <img src={bellImg} alt="bell" />
            </div>
            <h1 className="item-name">Bell</h1>
            <p className="item-desc">
              Inspirational Christmas Greetings Messages for your loved ones –
              whether you’re sending a small gift to grandma or need something
              special to write for.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetItems;
