import jQuery from "jquery";
import { toast } from "react-toastify";
import { showExceptions } from "./store/constant";

let httpCalls = [];

export const postCall = (setLoading) => {
  return (
    url,
    data,
    downloadRequest,
    fileName,
    ignoreException,
    stoploading,
    additionalHeaders
  ) => {
    if (!stoploading) {
      httpCalls.push(1);
      setLoading(true);
    }
    let headers = {};
    if (additionalHeaders) {
      headers = { ...additionalHeaders };
    }
    if (localStorage.getItem("token")) {
      headers["x-access-token"] = localStorage.getItem("token");
      headers["x-active-account"] = localStorage.getItem("account");
    }

    if (localStorage.getItem("admin-token")) {
      headers["x-access-admin-token"] = localStorage.getItem("admin-token");
    }

    const contentType = "application/json; charset=utf-8";
    const type = "post";
    return jQuery
      .ajax({
        url,
        data: JSON.stringify(data),
        headers,
        contentType,
        type,
        xhr: () => {
          let xhr = new XMLHttpRequest();
          if (downloadRequest) {
            xhr.responseType = "arraybuffer";
          }
          return xhr;
        },
      })
      .then(
        (resp) => {
          if (!stoploading) {
            httpCalls.pop();
            if (!httpCalls.length) {
              setTimeout(() => {
                setLoading(false);
              }, 100);
            }
          }
          if (downloadRequest) {
            try {
              var blob = new Blob([resp]);

              var downloadUrl = URL.createObjectURL(blob);
              var a = document.createElement("a");
              a.href = downloadUrl;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            } catch (exc) {
              console.log(
                "Save Blob method failed with the following exception."
              );
              console.log(exc);
            }
          }
          return resp;
        },
        (err) => {
          if (!stoploading) {
            httpCalls.pop();
            if (!httpCalls.length) {
              setTimeout(() => {
                setLoading(false);
              }, 100);
            }
          }
          if (err.responseJSON && err.responseJSON.logout) {
            localStorage.setItem("jwtExpired", "yes");
            localStorage.removeItem("token");
            localStorage.removeItem("admin-token");
            localStorage.removeItem("admin-name");
            localStorage.removeItem("admin-email");
            localStorage.removeItem("name");
            localStorage.removeItem("email");
            localStorage.removeItem("redirectUrl");
            window.location.href = "/";
            return;
          }

          if (!ignoreException && (err.status !== 500 || showExceptions())) {
            let message =
              "Oopppss! Something Went Wrong...Please Try again Later";
            if (err.responseJSON && err.responseJSON.message) {
              message = err.responseJSON.message;
            }
            toast(message);
          }

          throw err;
        }
      );
  };
};
