import SantaImgWithBag from "../assets/images/SantaImage.png";

interface ContainerProps {}

const GetYourGift: React.FC<ContainerProps> = () => {
  return (
    <div className="get-your-gift">
      <div className="left-img">
        <img src={SantaImgWithBag} alt="Santa with bag" />
      </div>
      <div className="right-content">
        <h1 className="content-heading">Santa claus is Coming</h1>
        <p className="content-desc">
          Fill your Christmas holiday with good cheer, joyful decorations, and
          unforgettable experience. Feel free to attend our Christmas event with
          your children and family and celebrate the most anticipated winter
          holiday. From Christmas carols to activities for adults and children,
          Sannta has a lot to offer.
        </p>
        <button className="get-gift-btn">Get Your Gift</button>
      </div>
    </div>
  );
};

export default GetYourGift;
