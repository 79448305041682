import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import "./scss/app.scss";
import Routes from "./shared/Routes";
import LoggedInRoutes from "./shared/LoggedInRoutes";
import { ErrorBoundary } from "./wrapper";
import { legacy_createStore as createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./store/reducer";

const store = createStore(reducer);

setupIonicReact();

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <IonApp>
          <IonReactRouter>
            {!localStorage.getItem("token") ? <Routes /> : <LoggedInRoutes />}
          </IonReactRouter>
        </IonApp>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
