import SantaLogo from "../assets/images/mms_logo.png";
import Santa from "../assets/images/Santa.png";
import BgTree from "../assets/images/BgTree.png";
import Bell1 from "../assets/images/Bell1.png";
import { useHistory } from "react-router";

interface ContainerProps {}

const HeaderSection: React.FC<ContainerProps> = () => {
  let history = useHistory();

  return (
    <div className="header">
      <div className="title-bar">
        <div className="logo">
          <div className="logo-img">
            <img src={SantaLogo} alt="Logo" />
          </div>
          <div className="logo-text">
            Match<span className="highlighted-text">MySanta</span>
          </div>
        </div>
        <nav className="nav">
          <ul className="nav-list">
            <li className="highlighted">New</li>
            <li>Popular</li>
            <li>Gifts</li>
            <li>Cards</li>
          </ul>
        </nav>
        <div className="signing-in">
          <div
            className="in"
            onClick={() => {
              history.push("/login");
            }}
          >
            Sign In
          </div>
          <button
            className="up"
            onClick={() => {
              history.push("/signup");
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
      <div className="tagline">
        <div className="tagline-left">
          <h1 className="header-text">Hey, it’s Christmas time!</h1>
          <p className="desc-text">
            Find the perfect holiday gift for everyone on your list this year,
            no matter what’s your budget.
          </p>
          <button className="action-btn">Explore Now</button>
        </div>
        <div className="tagline-right">
          <div className="circle"></div>
          <div className="BgTree-img">
            <img src={BgTree} alt="Background Tree" />
          </div>
          <div className="santa-img">
            <img src={Santa} alt="Santa" />
          </div>
          <div className="bell-img">
            <img src={Bell1} alt="Bell1" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
