import SantaWithDeers from "../assets/images/SantaWithDeers.png";

interface ContainerProps {}

const ContactToJoin: React.FC<ContainerProps> = () => {
  return (
    <div className="page-parent-wrapper">
      <div className="page-parent">
        <div className="contact-to-join">
          <div className="left-body">
            <h1 className="left-heading">Join the party</h1>
            <div className="contact-card">
              <div className="card-heading">
                <h2>Contact Us</h2>
                <p>Please fill this form in a decent manner</p>
              </div>
              <div className="text-inputs">
                <input
                  className="input-ctrl"
                  placeholder="Full Name"
                  type="text"
                />
                <input
                  className="input-ctrl"
                  placeholder="Email Address"
                  type="text"
                />
                <textarea
                  className="input-ctrl"
                  placeholder="Subject"
                  rows={3}
                  cols={100}
                ></textarea>
              </div>
              <button className="submit-btn">Submit</button>
            </div>
          </div>
          <div className="right-body">
            <img src={SantaWithDeers} alt="SantaWithDeers" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactToJoin;
