import ChristmasTree from "../assets/images/TreeImage.png";

interface ContainerProps {}

const JoinTheParty: React.FC<ContainerProps> = () => {
  return (
    <div className="join-the-party">
      <div className="left-content">
        <h1 className="content-heading">Christmas wish and party</h1>
        <p className="content-desc">
          Fill your Christmas holiday with good cheer, joyful decorations, and
          unforgettable experience. Feel free to attend our Christmas event with
          your children and family and celebrate the most anticipated winter
          holiday. From Christmas carols to activities for adults and children,
          Sannta has a lot to offer.
        </p>
        <button className="join-party-btn">Join the Party</button>
      </div>
      <div className="right-img">
        <img src={ChristmasTree} alt="Christmas Tree" />
      </div>
    </div>
  );
};

export default JoinTheParty;
