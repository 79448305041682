import { useState } from "react";
import { connect } from "react-redux";
import { postCall } from "../../http-services";
import actionTypes from "../../store/actions";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import { ReactComponent as TopLeftImage } from "../../assets/svgs/topLeftImage.svg";
import { ReactComponent as TopRightImage } from "../../assets/svgs/topRightImage.svg";
import { ReactComponent as BottomLeftImage } from "../../assets/svgs/bottomLeftImage.svg";
import { ReactComponent as BottomRightImage } from "../../assets/svgs/bottomRightImage.svg";
import { useHistory } from "react-router";

interface LoginProps {
  setLoading: any;
}

const ResetPassword: React.FC<LoginProps> = ({ setLoading }) => {
  let [email, setEmail] = useState("");
  let history = useHistory();

  let isInvaidEmail = (email: string) => {
    return (
      !email || !email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    );
  };

  return (
    <div className="reset-pswd">
      <div className="top-left">
        <TopLeftImage />
      </div>
      <div className="top-right">
        <TopRightImage />
      </div>
      <div className="bottom-left">
        <BottomLeftImage />
      </div>
      <div className="bottom-right">
        <BottomRightImage />
      </div>
      <div className="page-center">
        <h1>Reset Password</h1>
        <div className="user-name">
          <div className="lbl">Email</div>
          <div className="input-icon">
            <i className="user-icon">
              <span className="fa-solid fa-envelope fa-2x"></span>
            </i>

            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvaidEmail(email) ? " error-class" : " noerror-class")
              }
              type="text"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Email Address"
            />
          </div>
        </div>
        <div className="login-btn">
          <button
            className="login-btn"
            onClick={() => {
              postCall(setLoading)(getBaseApi() + BackendAPIs.auth.resetPwd, {
                email,
              }).then((resp) => {
                history.push("/reset-pwd/" + resp.redirect);
              });
            }}
            disabled={isInvaidEmail(email)}
          >
            Send OTP to Reset Password
          </button>
        </div>
        <div className="sign-up-link">
          <p className="in-case">Don’t have an account?</p>
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/signup");
            }}
          >
            Sign Up Now
          </p>
        </div>
        <div className="sign-up-link">
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/home");
            }}
          >
            &larr; Go back to home
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoading: (isLoading: any) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
