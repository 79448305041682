import FooterBottom from "../assets/images/FooterBottom.png";
import FBlogo from "../assets/images/fb.png";
import Instalogo from "../assets/images/insta.png";

interface ContainerProps {}

const FooterBar: React.FC<ContainerProps> = () => {
  return (
    <div className="footer-parent">
      <div className="footer-bar">
        <div className="text-block">
          <h1>Stay In Touch</h1>
          <div className="socials">
            <p>Don't be a stranger and start following us:</p>
            <div className="social-logo">
              <img src={FBlogo} alt="facebook" />
              <img src={Instalogo} alt="instagram" />
            </div>
          </div>
        </div>
        <div className="bottom-img">
          <img src={FooterBottom} alt="FooterBottom" />
        </div>
      </div>
    </div>
  );
};

export default FooterBar;
