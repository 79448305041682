import { Redirect, Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { ReactComponent as Loading } from "../loading.svg";
import Welcome from "../pages/Welcome/Welcome";
import AdminEmails from "../pages/AdminEmails/AdminEmails";
import GlobalProps from "../pages/GlobalProps/GlobalProps";
import JWT from "../pages/JWT/JWT";
import ScheduleTask from "../pages/ScheduleTask/ScheduleTask";
import SideNavs from "../pages/SideNavs/SideNavs";
import Sidebar from "../layouts/Sidebar";
import Header from "../layouts/Header";
import { Container } from "reactstrap";
import Home from "../pages/Home/Home";

const getWrappedComponent = (
  Comp: React.FC,
  isLoading: boolean,
  hideSideNav: boolean = false
) => {
  return (
    <div className="wrapper">
      {isLoading ? (
        <div className="loading">
          <Loading />
        </div>
      ) : null}
      {!hideSideNav ? (
        <div className="inner-wrapper">
          <main>
            <div className="pageWrapper d-lg-flex">
              <aside className="sidebarArea shadow1" id="sidebarArea">
                <Sidebar />
              </aside>

              <div className="contentArea">
                <Header />
                <Container className="p-4 main-content" fluid>
                  <Comp />
                </Container>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <div className="inner-wrapper">
          <Comp />
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

interface RoutesInterface {
  isLoading: boolean;
}
const LoggedInRoutes: React.FC<RoutesInterface> = ({ isLoading }) => {
  return (
    <IonRouterOutlet>
      <Route exact path="/welcome">
        {getWrappedComponent(Welcome, isLoading)}
      </Route>
      <Route exact path="/admin-emails">
        {getWrappedComponent(AdminEmails, isLoading)}
      </Route>
      <Route exact path="/global-props">
        {getWrappedComponent(GlobalProps, isLoading)}
      </Route>
      <Route exact path="/jwt">
        {getWrappedComponent(JWT, isLoading)}
      </Route>
      <Route exact path="/scheduled-tasks">
        {getWrappedComponent(ScheduleTask, isLoading)}
      </Route>
      <Route exact path="/side-navs">
        {getWrappedComponent(SideNavs, isLoading)}
      </Route>
      <Route exact path="/home">
        {getWrappedComponent(Home, isLoading, true)}
      </Route>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
    </IonRouterOutlet>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.isLoading,
  };
};

export default connect(mapStateToProps, {})(LoggedInRoutes);
