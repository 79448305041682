import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import Aux from "../hoc/_Aux";

class CustomTable extends Component {
  render() {
    return <Aux></Aux>;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomTable);
