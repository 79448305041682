import { useState } from "react";
import { connect } from "react-redux";
import { postCall } from "../../http-services";
import actionTypes from "../../store/actions";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import { ReactComponent as TopLeftImage } from "../../assets/svgs/topLeftImage.svg";
import { ReactComponent as TopRightImage } from "../../assets/svgs/topRightImage.svg";
import { ReactComponent as BottomLeftImage } from "../../assets/svgs/bottomLeftImage.svg";
import { ReactComponent as BottomRightImage } from "../../assets/svgs/bottomRightImage.svg";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router";

interface SignUpProps {
  setLoading: any;
}

interface Params {
  id: string;
}

const ConfirmResetPassword: React.FC<SignUpProps> = ({ setLoading }) => {
  let [otp, setOTP] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setCPassword] = useState("");
  let history = useHistory();

  let isInvaidPassword = (pwd: string) => {
    return (
      pwd.length < 8 ||
      pwd.length > 15 ||
      !pwd.match(/[a-z]/g) ||
      !pwd.match(/[0-9]/g)
    );
  };

  let isInvaidCPwd = (pwd: string, cPwd: string) => {
    return (
      pwd.length < 8 ||
      pwd.length > 15 ||
      !pwd.match(/[a-z]/g) ||
      !pwd.match(/[0-9]/g) ||
      pwd !== cPwd
    );
  };

  let isInvaidOTP = (otp: string) => {
    return !otp || otp.length < 6 || otp.length > 6;
  };

  let { id } = useParams<Params>();

  return (
    <div className="reset-pwd-action">
      <div className="top-left">
        <TopLeftImage />
      </div>
      <div className="top-right">
        <TopRightImage />
      </div>
      <div className="bottom-left">
        <BottomLeftImage />
      </div>
      <div className="bottom-right">
        <BottomRightImage />
      </div>
      <div className="page-center">
        <h1>Reset Password</h1>
        <div className="user-name">
          <div className="lbl">OTP</div>
          <div className="input-icon">
            <i className="user-icon">
              <span className="fa-solid fa-lock fa-2x"></span>
            </i>
            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvaidOTP(otp) ? " error-class" : " noerror-class")
              }
              type="text"
              value={otp}
              onChange={(e) => {
                setOTP(e.target.value);
              }}
              placeholder="OTP (received over mail)"
            />
          </div>
        </div>
        <div className="pswd">
          <div className="lbl">New Password</div>
          <div className="input-icon">
            <i className="user-icon">
              <span className="fa-solid fa-key fa-2x"></span>
            </i>

            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvaidPassword(password) ? " error-class" : " noerror-class")
              }
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
            />
          </div>
        </div>
        <div className="cnfm-pswd">
          <div className="lbl">Confirm Password</div>
          <div className="input-icon">
            <i className="user-icon">
              <span className="fa-solid fa-key fa-2x"></span>
            </i>

            <input
              autoComplete="off"
              className={
                "input-ctrl" +
                (isInvaidCPwd(password, confirmPassword)
                  ? " error-class"
                  : " noerror-class")
              }
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setCPassword(e.target.value);
              }}
              placeholder="Confirm Password"
            />
          </div>
        </div>
        <div className="reset-btn">
          <button
            className="reset-btn"
            onClick={() => {
              postCall(setLoading)(
                getBaseApi() + BackendAPIs.auth.resetPwdAction,
                {
                  id,
                  otp,
                  password,
                  confirmPassword,
                }
              ).then((resp) => {
                if (resp.data) {
                  toast("Password Changed Successfully!");
                  setTimeout(() => {
                    history.push("/login");
                  }, 2000);
                }
              });
            }}
            disabled={
              isInvaidPassword(password) ||
              isInvaidOTP(otp) ||
              isInvaidCPwd(password, confirmPassword)
            }
          >
            Reset Password
          </button>
        </div>
        <div className="login-link">
          <p
            className="highlighted cursor-pointer"
            onClick={() => {
              history.push("/home");
            }}
          >
            &larr; Go back to home
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoading: (isLoading: any) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmResetPassword);
