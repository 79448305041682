import React from "react";
import { Button, Form, Modal } from "reactstrap";
import { Component } from "react";
import { Col, Row } from "reactstrap";
import Aux from "../../hoc/_Aux";
import { postCall } from "../../http-services";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import CustomTable from "../../UIElements/CustomTable";
import { connect } from "react-redux";
import actionTypes from "../../store/actions";

class AdminEmails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminList: [],
      columns: [
        { title: "Id", name: "_id", type: "string" },
        { title: "Name", name: "name", type: "string" },
        { title: "Email", name: "email", type: "string" },
        { title: "Role", name: "role", type: "string" },
      ],
      criteria: {
        currentPage: 1,
        totalPages: 1,
        sortColumn: null,
        sortDirection: null,
        filteredColumns: [],
        filterValues: {},
      },
      columnOps: {},
      showModal: false,
      showDeleteModal: false,
      modalData: {
        heading: "",
        data: {
          name: "",
          email: "",
        },
      },
      deleteModalData: {
        heading: "",
        data: {
          email: "",
        },
      },
    };
  }

  componentDidMount() {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.email,
      {}
    ).then((resp) => {
      this.setState({
        adminList: resp.data,
      });
    });
  }
  addNewUserModal() {
    this.setState({
      modalData: {
        heading: "Create New Admin",
        data: {
          name: "",
          email: "",
        },
        editMode: false,
      },
      showModal: true,
    });
  }

  setModalShow(showModal) {
    this.setState({ showModal });
  }

  setDeleteModalShow(showDeleteModal) {
    this.setState({ showDeleteModal });
  }

  saveUser(data) {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.add,
      data
    ).then((resp) => {
      postCall(this.props.setLoading)(
        getBaseApi() + BackendAPIs.admin.email,
        {}
      ).then((resp) => {
        this.setState({
          adminList: resp.data,
          showModal: false,
        });
      });
    });
  }

  openDeleteUserconfirmationModal(admin) {
    this.setState({
      deleteModalData: {
        heading: "Delete User - " + admin.email,
        data: JSON.parse(JSON.stringify(admin)),
      },
      showDeleteModal: true,
    });
  }

  deleteUser({ email }) {
    postCall(this.props.setLoading)(getBaseApi() + BackendAPIs.admin.delete, {
      email,
    }).then((user) => {
      postCall(this.props.setLoading)(
        getBaseApi() + BackendAPIs.admin.email,
        {}
      ).then((resp) => {
        this.setState({
          adminList: resp.data,
          showDeleteModal: false,
        });
      });
    });
  }

  render() {
    let adminList = this.state.adminList;
    let actions = [
      {
        name: "delete",
        text: "Delete",
        variant: "outline-danger",
        handler: (admin) => {
          this.openDeleteUserconfirmationModal(admin);
        },
      },
    ];

    return (
      <Aux>
        <Row>
          <Col>
            <Button
              className="add-new-user"
              variant="outline-dark"
              size="sm"
              onClick={() => this.addNewUserModal()}
            >
              Add New Admin
            </Button>
          </Col>
        </Row>
        {adminList && adminList.length > 0 ? (
          <Row>
            <Col>
              <CustomTable
                columns={this.state.columns}
                criteria={this.state.criteria}
                data={this.state.adminList}
                columnOps={this.state.columnOps}
                wapperClassName="height60vh"
                actions={actions}
              ></CustomTable>

              <AddAdminModal
                show={this.state.showModal}
                onHide={() => this.setModalShow(false)}
                modalData={this.state.modalData}
                save={(data) => {
                  this.saveUser(data);
                }}
              />

              <DeleteUserModal
                show={this.state.showDeleteModal}
                onHide={() => this.setDeleteModalShow(false)}
                modalData={this.state.deleteModalData}
                deleteUser={(data) => {
                  this.deleteUser(data);
                }}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <AddAdminModal
                show={this.state.showModal}
                onHide={() => this.setModalShow(false)}
                modalData={this.state.modalData}
                save={(data) => {
                  this.saveUser(data);
                }}
              />
            </Col>
          </Row>
        )}
      </Aux>
    );
  }
}

class DeleteUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: props.modalData.data.email,
      typedEmail: "",
    };
  }

  componentDidUpdate(oldProps) {
    if (oldProps.modalData.data.email !== this.props.modalData.data.email) {
      this.setState({
        email: this.props.modalData.data.email,
        typedEmail: "",
      });
    }
  }

  changeEmail($event) {
    this.setState({ typedEmail: $event.target.value });
  }

  deleteUser() {
    const { email } = this.state;
    this.props.deleteUser({ email });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.modalData.heading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-user-form-body">
          <p>
            Are you sure you want to delete user (
            <code>{this.state.email}</code>) ?.
          </p>
          <Form>
            <Form.Group controlId="exampleForm.email">
              <Form.Label>Confirm Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={this.state.typedEmail}
                onChange={this.changeEmail.bind(this)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" onClick={this.props.onHide}>
            Close
          </Button>
          <Button
            size="sm"
            variant="dark"
            disabled={this.state.email !== this.state.typedEmail}
            onClick={this.deleteUser.bind(this)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class AddAdminModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
    };
  }

  componentDidMount() {}

  changeEmail($event) {
    this.setState({ email: $event.target.value });
  }
  changeName($event) {
    this.setState({ name: $event.target.value });
  }

  save() {
    const { name, email } = this.state;
    this.props.save({ name, email });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.modalData.heading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-user-form-body">
          <Form>
            <Form.Group controlId="exampleForm.email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={this.state.email}
                onChange={this.changeEmail.bind(this)}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.name">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Will Smith"
                value={this.state.name}
                onChange={this.changeName.bind(this)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-dark" onClick={this.props.onHide}>
            Close
          </Button>
          <Button
            size="sm"
            variant="dark"
            disabled={
              this.state.name.length === 0 || this.state.email.length === 0
            }
            onClick={this.save.bind(this)}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collapseMenu: state.collapseMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEmails);
