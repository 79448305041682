import React from "react";
import { Component } from "react";
import { Col, Row } from "reactstrap";
import Aux from "../../hoc/_Aux";
import { postCall } from "../../http-services";
import { BackendAPIs, getBaseApi } from "../../store/constant";
import CustomTable from "../../UIElements/CustomTable";
import { connect } from "react-redux";
import actionTypes from "../../store/actions";

class ScheduleTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskNameList: [],
      selectedTaskName: "",
      offerListGrant: [],
      offerListRevoke: [],
      columns: [
        { title: "Id", name: "_id", type: "string" },
        { title: "Name", name: "name", type: "string" },
        { title: "Email", name: "email", type: "string" },
      ],
      criteria: {
        currentPage: 1,
        totalPages: 1,
        sortColumn: null,
        sortDirection: null,
        filteredColumns: [],
        filterValues: {},
      },
      columnOps: {},
      showModal: false,
      showRevokeModal: false,
      modalData: {
        heading: "",
        data: {
          _id: "",
        },
      },
      revokeModalData: {
        heading: "",
        data: {
          _id: "",
        },
      },
    };
  }

  componentDidMount() {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.scheduleTaskList,
      {}
    ).then((resp) => {
      if (resp.data && resp.data.length) {
        this.setState({
          taskNameList: resp.data,
        });

        this.taskChanged(resp.data[0].taskName);
      }
    });
  }

  taskChanged = function (val) {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.scheduleTaskOffer,
      {
        taskName: val,
      }
    ).then((resp) => {
      if (
        resp &&
        (resp.revokeData || resp.grantData) &&
        (resp.revokeData.length || resp.grantData.length)
      ) {
        this.setState({
          selectedTaskName: val,
          offerListGrant: resp.grantData,
          offerListRevoke: resp.revokeData,
        });
      } else {
        this.setState({ selectedPropsType: val });
      }
    });
  };
  setModalShow(showModal) {
    this.setState({ showModal });
  }

  setDeleteModalShow(showRevokeModal) {
    this.setState({ showRevokeModal });
  }
  openRevokeOfferconfirmationModal(user) {
    this.setState({
      revokeModalData: {
        heading: `Revoke ${this.state.selectedTaskName} for  ${user.email}`,
        data: JSON.parse(JSON.stringify(user)),
      },
      showRevokeModal: true,
    });
  }
  openGrantOfferconfirmationModal(user) {
    this.setState({
      modalData: {
        heading: `Grant ${this.state.selectedTaskName} for  ${user.email}`,
        data: JSON.parse(JSON.stringify(user)),
      },
      showModal: true,
    });
  }

  revokeOffer({ userId }) {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.revokeTask,
      {
        userId,
        taskName: this.state.selectedTaskName,
      }
    ).then((user) => {
      postCall(this.props.setLoading)(
        getBaseApi() + BackendAPIs.admin.scheduleTaskOffer,
        {
          taskName: this.state.selectedTaskName,
        }
      ).then((resp) => {
        this.setState({
          offerListGrant: resp.grantData,
          offerListRevoke: resp.revokeData,
          showRevokeModal: false,
        });
      });
    });
  }

  grantOffer({ userId }) {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.grantTask,
      {
        userId,
        taskName: this.state.selectedTaskName,
      }
    ).then((user) => {
      postCall(this.props.setLoading)(
        getBaseApi() + BackendAPIs.admin.scheduleTaskOffer,
        {
          taskName: this.state.selectedTaskName,
        }
      ).then((resp) => {
        this.setState({
          offerListGrant: resp.grantData,
          offerListRevoke: resp.revokeData,
          showModal: false,
        });
      });
    });
  }

  triggerBatchProcess() {
    postCall(this.props.setLoading)(
      getBaseApi() + BackendAPIs.admin.triggerBatchProcess,
      {
        taskName: this.state.selectedTaskName,
      }
    ).then((user) => {});
  }

  render() {
    let offerListGrant = this.state.offerListGrant;
    let offerListRevoke = this.state.offerListRevoke;
    let action1 = [
      {
        name: "grant",
        text: "Grant",
        variant: "outline-warning",
        handler: (user) => {
          this.grantOffer(user);
        },
      },
    ];
    let action2 = [
      {
        name: "revoke",
        text: "Revoke",
        variant: "outline-danger",
        handler: (user) => {
          this.revokeOffer(user);
        },
      },
    ];

    return (
      <Aux>
        <Row>
          <Col>
            <form>
              <div className="from-group" controlId="exampleForm.plan">
                <label className="form-label">Task Name</label>
                <div className="schedult-task-grid">
                  <select
                    className="form-control"
                    value={this.state.selectedTaskName}
                    onChange={(e) => {
                      this.taskChanged(e.target.value);
                    }}
                  >
                    {this.state.taskNameList.map((d, i) => {
                      return (
                        <option value={d.taskName} key={i}>
                          {d.taskName} - {d.taskDesc}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="btn btn-dark"
                    onClick={() => {
                      this.triggerBatchProcess();
                    }}
                    size="sm"
                  >
                    Trigger Batch Process
                  </button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
        <br />
        {offerListGrant && offerListGrant.length > 0 ? (
          <Row>
            <Col>
              <CustomTable
                columns={this.state.columns}
                criteria={this.state.criteria}
                data={this.state.offerListGrant}
                columnOps={this.state.columnOps}
                wapperClassName="height60vh"
                actions={action2}
              ></CustomTable>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <br />
        {offerListRevoke && offerListRevoke.length > 0 ? (
          <Row>
            <Col>
              <CustomTable
                columns={this.state.columns}
                criteria={this.state.criteria}
                data={this.state.offerListRevoke}
                columnOps={this.state.columnOps}
                wapperClassName="height60vh"
                actions={action1}
              ></CustomTable>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <br />
        {offerListRevoke &&
        offerListRevoke.length === 0 &&
        offerListGrant &&
        offerListGrant.length === 0 ? (
          <Row>
            <Col>
              <p>No record to display</p>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </Aux>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collapseMenu: state.collapseMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (isLoading) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTask);
