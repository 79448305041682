import { Redirect, Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";
import Login from "../pages/Login/Login";
import Signup from "../pages/Signup/Signup";
import React from "react";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";

import { ReactComponent as Loading } from "../loading.svg";
import Home from "../pages/Home/Home";
import ValidateEmail from "../pages/ValidateEmail/ValidateEmail";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import ConfirmResetPassword from "../pages/ConfirmResetPassword/ConfirmResetPassword";

const getWrappedComponent = (Comp: React.FC, isLoading: boolean) => {
  return (
    <div className="wrapper">
      {isLoading ? (
        <div className="loading">
          <Loading />
        </div>
      ) : null}
      <div className="inner-wrapper">
        <Comp />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

interface RoutesInterface {
  isLoading: boolean;
}

const Routes: React.FC<RoutesInterface> = ({ isLoading }) => {
  return (
    <IonRouterOutlet>
      <Route exact path="/login">
        {getWrappedComponent(Login, isLoading)}
      </Route>
      <Route exact path="/signup">
        {getWrappedComponent(Signup, isLoading)}
      </Route>
      <Route exact path="/validate-email/:id">
        {getWrappedComponent(ValidateEmail, isLoading)}
      </Route>
      <Route exact path="/reset-pwd">
        {getWrappedComponent(ResetPassword, isLoading)}
      </Route>
      <Route exact path="/reset-pwd/:id">
        {getWrappedComponent(ConfirmResetPassword, isLoading)}
      </Route>
      <Route exact path="/home">
        {getWrappedComponent(Home, isLoading)}
      </Route>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
    </IonRouterOutlet>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.isLoading,
  };
};

export default connect(mapStateToProps, {})(Routes);
