import actionTypes from "../../store/actions";
import { connect } from "react-redux";

interface WelcomeProps {
  isLoading: boolean;
  setLoading: any;
}

const Welcome: React.FC<WelcomeProps> = ({ isLoading }) => {
  return (
    <div className="welcome">
      Hello User, {isLoading ? "loading" : "notloading"}
      <br />
      <button
        onClick={() => {
          localStorage.removeItem("token");
          window.location.href = "/";
        }}
      >
        Click here to logout
      </button>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isLoading: state.isLoading,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoading: (isLoading: any) =>
      dispatch({
        type: actionTypes.SET_LOADING,
        isLoading,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
